var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.will-desc")) + " ")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("amendment.no-wasiat")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.no_wasiat,
      expression: "form.no_wasiat"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.no_wasiat
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "no_wasiat", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.type")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.wasiat_type,
      expression: "form.wasiat_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.wasiat_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "wasiat_type", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("business-partner")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.niaga_name,
      expression: "form.niaga_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.niaga_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "niaga_name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.branch_name,
      expression: "form.branch_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.branch_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "branch_name", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.date-wasiat")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_date,
      expression: "form.amendment_date"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.amendment_date
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_date", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.no-amendment")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_count,
      expression: "form.amendment_count"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.amendment_count
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_count", $event.target.value);
      }
    }
  })])])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.updateAmedment.incharged_by_id.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "staff"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.officer")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.updateAmedment.incharged_by_id.$model,
      expression: "$v.updateAmedment.incharged_by_id.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "staff"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.updateAmedment.incharged_by_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("pls-select-officer")) + " --")]), _vm._l(_vm.users, function (user) {
    return _c('option', {
      key: user.id,
      domProps: {
        "value": user.user.id
      }
    }, [_vm._v(" " + _vm._s(user.user.name) + " ")]);
  })], 2), _vm.$v.updateAmedment.incharged_by_id.$error && !_vm.$v.updateAmedment.incharged_by_id.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.officer")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e()])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1].includes(_vm.amendment_status) && _vm.userRole == 'Staff' ? _c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.updateUserDetails
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")]) : _vm._e(), [0].includes(_vm.amendment_status) && _vm.userRole == 'Client' ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pindaan.part_b',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }